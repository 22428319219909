import { render, staticRenderFns } from "./query-form.vue?vue&type=template&id=c5c2e74e&scoped=true&"
import script from "./query-form.vue?vue&type=script&lang=js&"
export * from "./query-form.vue?vue&type=script&lang=js&"
import style0 from "./query-form.vue?vue&type=style&index=0&id=c5c2e74e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5c2e74e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardText,VCol,VDialog,VRow,VSpacer,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("D:\\asyhub-master\\asyhub-maritime-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c5c2e74e')) {
      api.createRecord('c5c2e74e', component.options)
    } else {
      api.reload('c5c2e74e', component.options)
    }
    module.hot.accept("./query-form.vue?vue&type=template&id=c5c2e74e&scoped=true&", function () {
      api.rerender('c5c2e74e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/dashboard/pages/queries/query-form.vue"
export default component.exports